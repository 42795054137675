import { ReactComponent as Plus } from "../../../assets/images/settings/plus.svg";
import Loading from "../../common/Loading";
import EmptyState from "../../common/EmptyState";
import SettingsStationWorkAreaRow from "./SettingsStationWorkAreaRow";

const SettingsStationWorkAreaList = ({
  workAreaList,
  handleDelete,
  handleEdit,
  handleAddNewOpen,
  isAddDisabled,
  loading,
  isUserRestricted
}) => (
  <table>
    <thead>
      <tr>
        <th>Work Area</th>
        {/*<th>Auto Clock In/Out</th>*/}
        <th>Pay Rate</th>
        <th>
            {!isUserRestricted && (
                <button
                    className="companies-btn add-station"
                    onClick={handleAddNewOpen}
                    disabled={isAddDisabled}>
                    <Plus className="companies-add"/>
                </button>
            )}
        </th>
      </tr>
    </thead>

    <tbody>
      {loading ? (
        <tr className="statement-more-data-container">
          <td colSpan="16">
            <Loading />
          </td>
        </tr>
      ) : workAreaList.length < 1 ? (
        <tr className="statement-more-data-container">
          <td colSpan="16">
            <EmptyState title="No added Work Area" paddingBottom />
          </td>
        </tr>
      ) : (
        <>
          {workAreaList.map((el) => (
            <SettingsStationWorkAreaRow
              key={el.id}
              itemData={el}
              handleDelete={handleDelete}
              handleEdit={handleEdit}
              isUserRestricted={isUserRestricted}
            />
          ))}
        </>
      )}
    </tbody>
  </table>
);

export default SettingsStationWorkAreaList;
