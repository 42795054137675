import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Delete } from "../../../assets/images/run-payroll/delete-filled.svg";
import { ReactComponent as Edit } from "../../../assets/images/settings/edit.svg";
import { ReactComponent as ArrowDown } from "../../../assets/images/settings/arrow-down.svg";
import axios from "../../../axios";
import { actions as toastActions } from "../../../store/toast/toast.reducers";
import StationModal from "../../modals/StationModal";
import SettingsStationList from "./SettingsStationList";
import ConfirmDeleteModal from "../../modals/ConfirmDeleteModal";
import ChangeCSANumberModal from "../../modals/ChangeCSANumberModal";
import { getUser } from "../../../store/auth/auth.selectors";
import { isUserSourceRestricted } from "../../../helpers/helpers";

const SettingsCompaniesRow = ({ itemData, handleDelete, handleEdit, stationCount }) => {
  const dispatch = useDispatch();

  const userData = useSelector(getUser);

  const [isOpen, setIsOpen] = useState(false);
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const [stationList, setStationList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [addOpen, setAddOpen] = useState(false);
  const [editedStation, setEditedStation] = useState();
  const [isAddLoading, setIsAddLoading] = useState(false);

  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [deletedStation, setDeletedStation] = useState();

  const [isCsaOpen, setIsCsaOpen] = useState(false);

  const [workAreaCount, setWorkAreaCount] = useState(itemData.work_areas_count);

  const { name, stations_count, id, auto_clock_in } = itemData;

  const isUserRestricted = isUserSourceRestricted();

  useEffect(() => {
    if (!isNeedUpdate && isOpen) {
      fetchStations();
    }

    if (isNeedUpdate) {
      setIsNeedUpdate(false);
    }
  }, [isNeedUpdate, isOpen]);

  const handleOpenChange = () => {
    setIsOpen(!isOpen);
  };

  const fetchStations = () => {
    setLoading(true);

    axios
      .get(`/companies/${itemData.id}/stations`)
      .then((response) => {
        if (response.success) {
          setStationList(response.data.company.stations);
        }
      })
      .catch((err) =>
        dispatch(
          toastActions.toast({ code: "error", message: err.response?.data?.message || err.message })
        )
      )
      .finally(() => setLoading(false));
  };

  const handleAddOpen = () => setAddOpen(true);

  const handleAddNewOpen = (e) => {
    e.preventDefault();

    if (userData.is_subscribed && stationCount > 0) {
      setIsCsaOpen(true);
    } else {
      handleAddOpen();
    }
  };

  const handleCSAClose = () => {
    setIsCsaOpen(false);
    deletedStation && setDeletedStation();
  };

  const handleCSAChangeConfirm = () => {
    setAddOpen(true);
    setIsCsaOpen(false);
  };

  const handleAddClose = () => {
    setAddOpen(false);
    setEditedStation();
  };

  const handleEditConfirm = (values, handleCurClose) => {
    setIsAddLoading(true);

    const formData = new FormData();
    formData.append("company_id", id);

    for (const property in values) {
      formData.append(property, values[property]);
    }

    if (editedStation) {
      formData.append("_method", "PUT");

      axios
        .post(`/stations/${editedStation.id}`, formData)
        .then((response) => {
          if (response.success) {
            handleAddClose();
            handleCurClose && handleCurClose();
            setIsNeedUpdate(true);
          }
        })
        .catch((err) =>
          dispatch(
            toastActions.toast({
              code: "error",
              message: err.response?.data?.message || err.message
            })
          )
        )
        .finally(() => setIsAddLoading(false));
    } else {
      axios
        .post("/stations", formData)
        .then((response) => {
          if (response.success) {
            handleAddClose();
            handleCurClose && handleCurClose();
            setIsNeedUpdate(true);
          }
        })
        .catch((err) =>
          dispatch(
            toastActions.toast({
              code: "error",
              message: err.response?.data?.message || err.message
            })
          )
        )
        .finally(() => setIsAddLoading(false));
    }
  };

  const handleOpenEditModal = (item, e) => {
    e.preventDefault();

    setEditedStation(item);
  };

  const handleOpenDeleteModal = (item, e) => {
    e.preventDefault();

    setDeletedStation(item);
  };

  const handleDeleteConfirm = (isChecked, setIsChecked) => {
    setIsDeleteLoading(true);

    const body = {
      ...(isChecked && !!deletedStation?.employees_count ? { delete_employees: 1 } : {})
    };

    axios
      .delete(`/stations/${deletedStation.id}`, { data: body })
      .then((response) => {
        if (response.success) {
          handleCloseDeleteModal();
          setIsNeedUpdate(true);
          dispatch(toastActions.toast({ code: "success", message: "Station deleted success" }));
        }
      })
      .catch((err) =>
        dispatch(
          toastActions.toast({ code: "error", message: err.response?.data?.message || err.message })
        )
      )
      .finally(() => {
        setIsDeleteLoading(false);
        isChecked && setIsChecked(false);
      });
  };

  const handleCloseDeleteModal = () => setDeletedStation();

  return (
    <li>
      <div className="company-item">
        <div className="col-1" onClick={handleOpenChange}>
          <span>{name}</span>
          <div style={{ fontSize: '14px' }}>Auto Clock In/Out:</div>
          {auto_clock_in ? (
              <div className="auto-clock-in-enabled" style={{ margin: 'unset', marginLeft: '0.5rem' }}>Enabled</div>
          ) : (
              <div className="auto-clock-in-disabled" style={{ margin: 'unset', marginLeft: '0.5rem' }}>Turned off</div>
          )}
        </div>
        <div className="col-2" onClick={handleOpenChange}>
          No. of Station: {stationList.length || stations_count}
        </div>
        <div className="col-2" onClick={handleOpenChange}>
          No. of Work Area: {workAreaCount}
        </div>
        <div className="col-4" style={{ width: isUserRestricted ? '30px' : '150px' }}>
          {!isUserRestricted && (
              <>
                <button className="companies-btn edit" onClick={handleEdit.bind(null, itemData)}>
                  <Edit/>
                </button>
                <button className="companies-btn delete" onClick={handleDelete.bind(null, itemData)}>
                  <Delete className="companies-delete"/>
                </button>
              </>
          )}
          <button onClick={handleOpenChange} className={`companies-btn edit ${isOpen ? "up" : ""}`}>
            <ArrowDown/>
          </button>
        </div>
      </div>

      {isOpen && (
        <div className="companies-informatio-sec">
          <div className="table-scroll-mobile payroll-table stations">
            <SettingsStationList
              stationList={stationList}
              handleDelete={handleOpenDeleteModal}
              handleEdit={handleOpenEditModal}
              handleAddNewOpen={handleAddNewOpen}
              isAddDisabled={stationList.length >= 20}
              loading={loading}
              setWorkAreaCount={setWorkAreaCount}
              workAreaCount={workAreaCount}
              isUserRestricted={isUserRestricted}
            />
          </div>
        </div>
      )}

      <StationModal
        open={!!editedStation || addOpen}
        handleClose={handleAddClose}
        editedStation={editedStation}
        handleConfirm={handleEditConfirm}
        isLoading={isAddLoading}
      />

      <ChangeCSANumberModal
        open={isCsaOpen}
        handleClose={handleCSAClose}
        handleConfirm={handleCSAChangeConfirm}
      />

      <ConfirmDeleteModal
        open={!!deletedStation}
        handleClose={handleCloseDeleteModal}
        handleConfirm={handleDeleteConfirm}
        text="Are you sure delete this Station?"
        subtitle={
          deletedStation?.employees_count
            ? `There are ${deletedStation?.employees_count} employees on this Station, do you want to proceed?`
            : null
        }
        checkbox={!!deletedStation?.employees_count}
        isLoading={isDeleteLoading}
      />
    </li>
  );
};

export default SettingsCompaniesRow;
